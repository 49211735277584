<template>
	<v-tooltip bottom>
		<template v-slot:activator="{ on }">
			<v-btn :icon="hasIcon" :flat="!hasIcon" v-on="on" @click.stop="sort()">
				<v-badge color="" overlap :top="isAscSorting" :bottom="isDescSorting">
					<template v-slot:badge>
						<v-icon color="secondary">{{ arrowIcon }}</v-icon>
					</template>
					<v-layout justify-center align-center>
						<v-icon v-if="hasIcon" color="primary">{{ icon }}</v-icon>
					</v-layout>
				</v-badge>
			</v-btn>
		</template>
		<span v-text="tooltip" />
	</v-tooltip>
</template>

<script>

export default {
	name: 'SortButton',
	props: {
		value: {
			type: Object,
			required: false,
			default: null
		},
		icon: {
			type: String,
			required: false,
			default: null,
			validator: propValue => (propValue ? propValue.trim().length : true)
		},
		tooltipAsc: {
			type: String,
			required: true,
			validator: propValue => propValue.trim().length
		},
		tooltipDesc: {
			type: String,
			required: true,
			validator: propValue => propValue.trim().length
		},
		tooltipCancel: {
			type: String,
			required: false,
			default: null,
			validator: propValue => propValue.trim().length
		},
		hasCancel: {
			type: Boolean,
			required: false,
			default: false
		},
		field: {
			type: String,
			required: false,
			default: 'name'
		}
	},
	computed: {
		sortOrder: function () {
			return this.value.order 
		},
		sortField: function () {
			return this.value.field;
		},
		hasIcon: function () {
			return this.icon !== null
		},
		isAscSorting: function () {
			return this.sortOrder === 'asc'
		},
		isDescSorting: function () {
			return this.sortOrder === 'desc'
		},
		data: function () {
			let data = {
				arrow: {
					asc: 'arrow_upward',
					desc: 'arrow_downward'
				},
				tooltip: {
					asc: this.tooltipDesc,
					desc: this.tooltipAsc
				},
				nextOrder: {
					asc: 'desc',
					desc: 'asc'
				}
			}
			/** 
			 * Cancel tricky hack:
			 * object key needs to be a "string", including empty string
			 * empty string used to store the cancel issue
			 */
			if (this.hasCancel) {
				data.arrow[''] = ''
				data.tooltip[''] = this.tooltipAsc
				data.tooltip.desc = this.tooltipCancel
				data.nextOrder[''] = 'asc'
				data.nextOrder.desc = ''
			}
			return data
		},
		arrowIcon: function () {
			let arrowIcon = ''
			if (this.field !== this.sortField) {
				return arrowIcon
			}
			return this.data.arrow[this.sortOrder]
		},
		tooltip: function () {
			let tooltip = this.tooltipAsc
			if (this.field !== this.sortField) {
				return tooltip
			}
			return this.data.tooltip[this.sortOrder]
		}
	},
	methods: {
		sort: function () {
			let sort = { field: this.field, order: 'asc' }
			if (this.field === this.sortField) {
				sort.order = this.data.nextOrder[this.sortOrder]
			}
			this.$emit('sorted', sort)
		}
	}
}
</script>
